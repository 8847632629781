import React from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import HamzaResume from "../../lib/HamzaMughalResume.pdf";
import Logo from "../../images/main_logo.png";
import "./Header.css";

const Header = ({ home, about, contact, portfolio, service }) => {
    return (
        <Navbar expand="lg">
            <Container>
                <Navbar.Brand><img src={Logo} /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className="ml-auto">
                        <NavLink onClick={home} className='nav-link' to='/'>Home</NavLink>
                        <NavLink onClick={about} className='nav-link' to='/'>About</NavLink>
                        <NavLink onClick={portfolio} className='nav-link' to='/'>Portfolio</NavLink>
                        <NavLink onClick={service} className='nav-link' to='/'>Services</NavLink>
                        <NavLink onClick={contact} className='nav-link' to='/'>Contact</NavLink>
                        <a className="all_btn" style={{ width: 'fit-content' }} href={HamzaResume} download title={"HamzaAhmedMughalResume"}> Resume </a>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
export default Header;