import React from "react";
import "./Services.css";
import MernIcon from "../../images/mern.png";
import frontend from "../../images/frontend.png";
import ecommerce from "../../images/ecommerce.png";
import psd from "../../images/psd.png";

const Services = () => {
    return (
        <div className="services_main">
            <h1 className="main_heading">SERVICES</h1>

            <div className="container">
                <div className="row justify-content-around service_row">
                    <div className="col-md-5 service_section">
                        <div className="text-center">
                            <div>
                                <img src={frontend} alt='img' />
                            </div>
                        </div>
                        <h4>FRONT-END DEVELOPMENT</h4>
                        <div className="tags">
                            <span>HTML</span><span>CSS</span><span>Sass</span>
                            <span>ReactJs</span><span>NextJs</span>
                            <span>Tailwind-css</span><span>Material-UI</span><span>Bootstrap</span>
                            <span>Chakra-UI</span>
                            <span>SendGrid</span>
                        </div>
                    </div>
                    <div className="col-md-5 service_section">
                        <div className="text-center">
                            <img src={MernIcon} alt='img' />
                        </div>
                        <h4>MERN STACK DEVELOPMENT</h4>
                        <div className="tags">
                            <span>React JS</span>
                            <span>TypeScript</span>
                            <span>Node JS</span><span>Express JS</span>
                            <span>Mongo DB</span><span>Rest APIS</span><span>Stripe</span>
                        </div>
                    </div>
                    <div className="col-md-5 service_section">
                        <div className="text-center">
                            <img src={ecommerce} alt='img' />
                        </div>
                        <h4>E-COMMERCE DEVELOPMENT</h4>
                        <div className="tags">
                            <span>Admin Dashboard</span>
                            <span>React JS</span><span>TypeScript</span> <span>CMS</span>
                            <span>Stripe</span> <span>MUI DataTables</span> <span>Node JS</span>
                            <span>Express JS</span>
                            <span>Mongo DB</span>
                        </div>
                    </div>
                    <div className="col-md-5 service_section">
                        <div className="text-center">
                            <img src={psd} alt='img' />
                        </div>
                        <h4>PSD CONVERSION</h4>
                        <div className="tags">
                            <span>HTML</span><span>CSS</span><span>Sass</span>
                            <span>JavaScript</span><span>TypeScript</span>
                            <span>Tailwind-css</span><span>Bootstrap</span> <span>Animation </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Services