import React from "react";
import "./App.css";
import RouteConfig from "./Components/RouteConfig/RouteConfig";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const App = () => {
    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />
            <Router>
                <Routes>
                    <Route path={'/'} element={<RouteConfig />} />
                </Routes>
            </Router>
        </>
    )
}
export default App;