import React from "react";
import "./Banner.css";
import { BsArrowDown } from "react-icons/bs"

const Home = ({contact, about}) => {

    return (
        <div className="main_home">
            <div>
                <p>Hello I'm </p>
                <h1><span>HAMZA</span> AHMED MUGHAL</h1>
                <h2>SOFTWARE ENGINEER | REACT JS DEVELOPER</h2>
                <div className="text-center"> <button className="all_btn mt-3" onClick={contact}>Contact</button> </div>
            </div>
            <div className="started_arrow">
                <BsArrowDown onClick={about}/>
            </div>
        </div>
    )
}
export default Home;