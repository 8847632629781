import React, { useRef } from 'react';
import Home from "../../Container/Banner/Banner";
import Header from "../../Components/Header/Header";
import About from "../../Container/About/About";
import Contact from "../../Container/Contact/Contact";
import Portfolio from "../../Container/Portfolio/Portfolio";
import Services from "../../Container/Services/Services";
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import ParticleConfig from "../../Config/particle-config";

const RouteConfig = () => {
    const sections = {
        home: useRef(null),
        about: useRef(null),
        contact: useRef(null),
        portfolio: useRef(null),
        service: useRef(null)
    };

    const scrollToSection = (section) => {
        window.scrollTo({
            top: sections[section].current.offsetTop,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <Particles id="tsparticles" init={loadFull} options={ParticleConfig} />
            <Header home={() => scrollToSection('home')} about={() => scrollToSection('about')}
                service={() => scrollToSection('service')} portfolio={() => scrollToSection('portfolio')}
                contact={() => scrollToSection('contact')} />

            <div ref={sections.home}><Home contact={() => scrollToSection('contact')} about={() => scrollToSection('about')} /></div>
            <div ref={sections.about}><About /></div>
            <div ref={sections.portfolio}><Portfolio /></div>
            <div ref={sections.service}><Services /></div>
            <div ref={sections.contact}><Contact /></div>
        </>
    );
};
export default RouteConfig;