import React, { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import emailjs from '@emailjs/browser';
import Insta from "../../images/insta.png";
import Linkdln from "../../images/linkedln.png";
import facebook from "../../images/fb-icon.png";
import github from "../../images/github-icon.png";
import "./Contact.css";
import { errorNotify, successNotify } from "../../lib/toast";

const Contact = ({ ref }) => {
    const [loader, setLoader] = useState(false)

    const formSubmitHandler = (e) => {
        e.preventDefault();

        setLoader(true)
        emailjs.sendForm('service_c1k7dgq', 'template_bds2wit', e.target, 'SjFNCLoFp1ARJd_fX')
            .then((result) => {
                successNotify("Email sent Successfully!")
                setLoader(false)
            }, (error) => {
                errorNotify(error.text)
                setLoader(false)
            });
        e.target.reset();
    }

    return (
        <div className="contact_main" ref={ref} id={'contactId'}>
            <h1 className="main_heading">CONTACT</h1>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-4 contact_details">
                        <h5>Contact Details</h5>

                        <div className="my-4">
                            <p className="head">Phone Number</p>
                            <p className="text">+92 349 2496204</p>
                        </div>

                        <div className="my-4">
                            <p className="head">Email</p>
                            <p className="text">hamza19mughal99@gmail.com</p>
                        </div>

                        <div className="social_div my-4">
                            <p className="head">Social Links</p>
                            <div className="links">
                                <a href='https://www.instagram.com/mughal_hamza98/?hl=en' target='_blank'><img src={Insta} alt='insta' /></a>
                                <a href='https://www.linkedin.com/in/hamzamughal99/' target='_blank'><img src={Linkdln} alt='insta' /></a>
                                <a href='https://www.facebook.com/hamza.mughal.501598/' target='_blank'><img src={facebook} alt='insta' /></a>
                                <a href='https://github.com/hamza19mughal99' target='_blank'><img src={github} alt='insta' /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-7 contact_details">
                        <h5>Drop a Message</h5>
                        <Form onSubmit={formSubmitHandler}>
                            <div className="row">
                                <div className="col-md-6">
                                    <Form.Control type="text" placeholder="Name" name="name" required />
                                </div>
                                <div className="col-md-6">
                                    <Form.Control type="email" placeholder="Email" name="email" required />
                                </div>
                                <div className="col-md-12">
                                    <Form.Control as="textarea" rows={5} placeholder="Message" name="message" required />
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button type="submit" disabled={loader} className="all_btn">{loader ? <Spinner animation="border" size="sm" /> : 'Submit'} </button>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Contact;