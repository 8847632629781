import React from 'react';
import "./Portfolio.css";
import { FaGithub, FaExternalLinkAlt } from "react-icons/fa";
import { RiGitRepositoryPrivateLine } from "react-icons/ri"
import { allData } from './PortfolioData';

const Portfolio = () => {
    return (
        <div className='portfolio_main'>
            <h1 className="main_heading">PORTFOLIO</h1>

            <div>
                <div className='container'>
                    <div className='row justify-content-center'>
                        {
                            allData.map((data) => (
                                <div className='col-md-4'>
                                    <div className="card 1">
                                        <div className="card_image"> <img src={data.img} alt={'img'} /> </div>
                                        <div className="card_title title-white">
                                            <div>
                                                <h5>{data.name}</h5>
                                            </div>
                                            <div>
                                                <a href={data.url} target="_blank"><FaExternalLinkAlt /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Portfolio