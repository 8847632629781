import Aeropups from "../../images/aeropups.png";
import Tifi from "../../images/tifi.png";
import ComInfo from "../../images/cominfo.png"

import playerTotal from "../../images/player_total.png";
import buswrap from "../../images/buswrap.png";
import designAlligator from "../../images/design_alligator.png";
import vessels from "../../images/vessels.png";
import rareFind from "../../images/rare_find.png";
import breakthrough from "../../images/breakthrough.png";
import jet from "../../images/jet.png";
import cominfo_solution from "../../images/cominfo_solutions.png";
import comanjo from "../../images/comanjo.png";
import hookah_bars from "../../images/hookah_bars.png";
import sports_rmr from "../../images/rmr.png";
import techall from "../../images/techall.png";

export const allData = [
    {
        img: ComInfo,
        name: ' Cominfo',
        github: false,
        url: 'https://cominfo.com/'
    },
    {
        img: designAlligator,
        name: 'Design Alligators',
        github: false,
        url: 'https://designalligators.com/'
    },
    {
        img: techall,
        name: 'Tech & All',
        github: false,
        url: 'https://techandall.vercel.app/'
    },
    {
        img: comanjo,
        name: 'Comanjo',
        github: false,
        url: 'https://comanjo.com/'
    },
    {
        img: playerTotal,
        name: 'Player Totals',
        github: false,
        url: 'https://dev.playertotals.com/'
    },
    {
        img: hookah_bars,
        name: 'Hookahbars',
        github: false,
        url: 'https://hookahbars.com/'
    },
    {
        img: rareFind,
        name: 'Rare Find',
        github: false,
        url: 'https://rare-find.surge.sh/'
    },
    {
        img: Tifi,
        name: 'Tifi',
        github: false,
        url: 'https://tifi.net/'
    },
    {
        img: breakthrough,
        name: 'Break Through',
        github: false,
        url: 'https://gym-workout.surge.sh/'
    },
    {
        img: cominfo_solution,
        name: 'Cominfo Solutions',
        github: false,
        url: 'https://cominfosolutions.net/'
    },
    {
        img: buswrap,
        name: 'Buswrap',
        github: false,
        url: 'https://buswrap.com/'
    },
    {
        img: sports_rmr,
        name: 'Sports RMR',
        github: false,
        url: 'https://www.rmrecruits.com/'
    },
    {
        img: vessels,
        name: 'Vessel',
        github: false,
        url: 'https://vessel-luxury-rides.web.app/'
    },
    {
        img: Aeropups,
        name: 'Aeropups',
        github: false,
        url: 'https://www.aeropups.com/'
    },
    {
        img: jet,
        name: 'Share-a-jet',
        github: false,
        url: 'https://share-a-jet.surge.sh/'
    },
]