import React from "react";
import "./About.css";

const About = () => {
    return (
        <div className="main_about">
            <h1 className="main_heading">ABOUT</h1>

            <div className="container">
                <div className="row">
                    <div className="col-md-6 about_me">
                        <h5>About Me</h5>
                        <p>A Software Engineer with strong skills in front-end development. I enjoy working in competitive
                            environments and taking on challenging tasks. I am passionate about delivering optimized and highquality solutions. I specialize in developing web applications and use my experience to provide effective
                            and innovative solutions.
                        </p>
                        <p>Following are the courses that I have done in past: </p>

                        <ul>
                            <li>Certificate In Information Technology. (CERTIFIED)</li>
                            <li>Project Management Agile Scrum. (CERTIFIED)</li>
                        </ul>
                    </div>
                    <div className="col-md-6 skills">
                        <h5>Skills</h5>

                        <div className="progress_bar">
                            <p className="text-end">95%</p>
                            <div className="progress_div95" />
                            <p>HTML5</p>
                        </div>
                        <div className="progress_bar">
                            <p className="text-end">95%</p>
                            <div className="progress_div95" />
                            <p>CSS3</p>
                        </div>
                        <div className="progress_bar">
                            <p className="text-end">80%</p>
                            <div className="progress_div80" />
                            <p>JavaScript</p>
                        </div>
                        <div className="progress_bar">
                            <p className="text-end">80%</p>
                            <div className="progress_div80" />
                            <p>TypeScript</p>
                        </div>
                        <div className="progress_bar">
                            <p className="text-end">80%</p>
                            <div className="progress_div80" />
                            <p>React JS</p>
                        </div>
                        <div className="progress_bar">
                            <p className="text-end">80%</p>
                            <div className="progress_div80" />
                            <p>Next JS</p>
                        </div>
                        <div className="progress_bar">
                            <p className="text-end">65%</p>
                            <div className="progress_div65" />
                            <p>Node JS</p>
                        </div>
                        <div className="progress_bar">
                            <p className="text-end">65%</p>
                            <div className="progress_div65" />
                            <p>Express JS</p>
                        </div>
                        <div className="progress_bar">
                            <p className="text-end">65%</p>
                            <div className="progress_div65" />
                            <p>Mongo DB</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About